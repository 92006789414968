import React, { useState } from "react";
import "./AccordionSection.scss";
import { Container, Row, Col } from "react-bootstrap";
import { StaticQuery, graphql } from "gatsby";

interface Item {
  image: string;
  imageWebp?: string;
  title: string;
  sub?: string;
}

interface IProps {
  title: string;
  items: Item[];
  mainIndex: number;
  inverted?: boolean;
}

const AccordionSection: React.FC<IProps> = ({
  title,
  items,
  mainIndex,
  inverted,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <div className="accordionSectionContainer">
      <h1 className="h1 text-center">{title}</h1>
      <div className="spacer-sm"></div>
      <Container>
        <Row className="justify-content-around main-content px-xl-5 align-items-center">
          <Col
            lg={{ span: 5 }}
            className={`left d-none d-lg-flex align-items-start ${
              inverted && "order-2"
            }`}
          >
            {/* <img
              className="img-fluid large"
              src={items[selectedIndex].image}
              alt={items[selectedIndex].title}
              style={{ objectFit: "contain" }}
            /> */}
            <picture className="img-fluid large">
              <source srcSet={items[selectedIndex].image} />
              <source srcSet={items[selectedIndex].imageWebp} />
              <img alt={items[selectedIndex].title} />
            </picture>
            {/* <img
              className="img-fluid small"
              src={items[mainIndex].image}
              alt={items[mainIndex].title}
            /> */}
          </Col>
          <Col lg={{ span: 5 }} className="right">
            {items.map((item, index) => (
              <div
                key={index}
                className="item d-flex align-items-center"
                onClick={() => setSelectedIndex(index)}
              >
                <div className="content">
                  <h4 className="mb-0">{item.title}</h4>
                  {item.sub && (
                    <p
                      className={
                        selectedIndex == index ? "mt-2 mr-3" : "d-none"
                      }
                    >
                      {item.sub}
                    </p>
                  )}
                </div>
                {item.sub && (
                  <div>
                    <h1 className={`mb-0`}>
                      {selectedIndex === index ? "-" : "+"}
                    </h1>
                  </div>
                )}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccordionSection;
