import React from "react";
import "./FeatureExplorer.scss";
import { BsArrowRight } from "react-icons/bs";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

interface IProps {
  inverted?: boolean;
}

const FeatureExplorer: React.FC<IProps> = ({ inverted }) => {
  const options = {
    links: [
      {
        linkHeader: " Interactive 3D product renders",
        linkDesc:
          "Take the visual experience to the next level with interactive 3D images of your products.",
        link: "/interactive3D",
      },
      {
        linkHeader: "Product configurator",
        linkDesc:
          "Showcase each & every available product customization to your users in real time.",
        link: "/product-configurators",
      },
      {
        linkHeader: "AR/VR solution",
        linkDesc:
          "Let your customers experience life size products in their own or virtual environment seamlessly.",
        link: "/arvr",
      },
      {
        linkHeader: "AI driven background removal",
        linkDesc:
          "Create a standard product gallery of fine tuned car images for the professional look on your dealership website.",
        link: "/background-removal",
      },
      {
        linkHeader: "Metaverse",
        linkDesc:
          "Launch your store in Metaverse with HelloAR and extend your brand in mixed reality.",
        link: "/metaverse",
      },
    ],
  };
  const { links } = options;
  return (
    <div className="featureExplorer container">
      <h1 className="h1 w-100 w-xl-75 pr-0 pr-xl-5 text-left mb-1 mb-md-5 text-center">
        Bring your products to life in seconds
      </h1>

      <div className=" pt-3 row">
        <div className="image col-12 col-xl-6">
          <div className="player-wrapper">
            <VideoPlayer
              videoUrl="../../videos/ar-solution.mp4"
              videoThumbnail="../../images/thumbnail/featureexplorertext.webp"
            />
          </div>
        </div>
        <div className="ml-0 ml-xl-3 mt-1 mt-xl-0 col pl-0 pr-0 pl-md-3 pr-md-3">
          {links.map((content, index) => (
            <div key={index}>
              <div className="d-none d-md-flex flex-column">
                <div className="d-flex align-items-center">
                  <h3 className="content-header">{content.linkHeader}</h3>
                  <a
                    className="pl-3 d-flex align-items-center"
                    href={content.link}
                  >
                    Explore
                    <BsArrowRight className="ml-1" />
                  </a>
                </div>
                <p className="content-desc">{content.linkDesc}</p>
              </div>
              <div className="pt-3 d-flex d-md-none flex-column mb-3 mobile">
                <div className="d-flex align-items-start flex-column">
                  <h3 className="content-header">{content.linkHeader}</h3>
                  <p className="content-desc">{content.linkDesc}</p>
                  <a className="d-flex align-items-center" href={content.link}>
                    Explore
                    <BsArrowRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureExplorer;
