import React, { MouseEventHandler, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { BsArrowRight } from "react-icons/bs";
import "./DomainsCarousel.scss";

interface IProps {}

const DomainsCarousel: React.FC<IProps> = ({}) => {
  const [currentIndex, setcurrentIndex] = useState(0);
  const carousel = useRef<any>();

  let items = [
    {
      title: "Automotives",
      info: "Remove background, create 360 car views, upload & download images in bulk, damage tagging, highlight features with our platform in under 10 minutes.",
      imageUrlWebp: "../../images/domain/cars-360.webp",
      imageUrlPng: "../../images/domain/cars-360.png",
      pageUrl: "/automotive",
    },
    {
      title: "Furniture",
      info: "Create 3D renders of your products, add real time interactions and product configurations, and AR capabilities with our proprietary easy to use softwares in just a few steps.",
      imageUrlWebp: "../../images/domain/ar-furniture-app.webp",
      imageUrlPng: "../../images/domain/ar-furniture-app.png",
      pageUrl: "/furniture",
    },
    {
      title: "Home Furnishing",
      info: "Empowers your users with real life visualization to make sound decisions in under 10 minutes. You could also add our product configurator for seamless user experience.",
      imageUrlWebp:
        "../../images/domain/augmented-reality-home-improvement.webp",
      imageUrlPng: "../../images/domain/augmented-reality-home-improvement.png",
      pageUrl: "/home-furnishing",
    },
    {
      title: "Sanitarywares",
      info: "Crystal clear 3D visualisations of all the products and their configurations in the perfect virtual setting.",
      imageUrlWebp: "../../images/domain/augmented-reality-applications.webp",
      imageUrlPng: "../../images/domain/augmented-reality-applications.png",
      pageUrl: "/sanitary-ware",
    },
  ];

  const goToSlide = (index: number) => {
    setcurrentIndex(index);
    carousel.current.goTo(index);
  };

  return (
    <div className="domainscontainer w-100">
      <div className="pills w-100 d-flex align-items-center justify-content-center">
        {items.map((item, index) => (
          <div
            className="domain-name"
            key={index}
            onClick={() => goToSlide(index)}
          >
            <div className={`pill ${currentIndex == index ? "active" : ""}`}>
              <p className="mx-3">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="domaincarousel">
        <Carousel
          ref={carousel}
          showArrows={false}
          onPrevEnd={(obj, index) => {
            setcurrentIndex(index);
          }}
          onNextEnd={(obj, index) => {
            setcurrentIndex(index);
          }}
        >
          {items.map((item, index) => (
            <div key={index}>
              <div className="item">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="container-fluid d-flex align-items-center">
                    <div className="row containerrow">
                      <div className="col-12 col-xl-6 pl-0 pr-0">
                        <div className="content">
                          <h1 className="title">{item.title}</h1>
                          <h5 className="dark-text my-3 info">{item.info}</h5>
                          <a href={item.pageUrl} className="d-flex m-0">
                            <h4 className="know text-white">
                              Know more
                              <BsArrowRight className="ml-2" />
                            </h4>
                          </a>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 pl-0 pr-0">
                        <div className="position-relative h-100 d-flex align-items-center justify-content-center">
                          <div className="box">
                            <div className="boxinner">
                              <picture>
                                <source srcSet={item.imageUrlWebp} />
                                <source srcSet={item.imageUrlPng} />
                                <img alt={item.title} />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default DomainsCarousel;
