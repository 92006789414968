import { graphql } from "gatsby";
import React from "react";
import "../styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import DomainsCarousel from "../components/DomainsCarousel/DomainsCarousel";
import FeatureExplorer from "../components/FeatureExplorer/FeatureExplorer";
import Testimonials from "../sections/homepage/Testimonials";
import CaseStudy from "../sections/homepage/CaseStudy";
import Blogs from "../sections/homepage/Blogs";
import ButtonBanner from "../components/ButtonBanner";
import ClientLogoBanner from "../components/ClientLogoBanner";
import CommonSection from "../components/CommonSection";
import StatColumn from "../components/StatColumn";
import AccordionSection from "../components/AccordionSection";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
const Home = () => {
  let logos = [
    "../../images/trustedclients/cars24-1.svg",
    "../../images/trustedclients/featherlite1.svg",
    "../../images/trustedclients/ashirvad1.svg",
    "../../images/trustedclients/spinny1.svg",
    "../../images/trustedclients/giga.svg",
    "../../images/trustedclients/wakefit1.svg",
    "../../images/trustedclients/danubehome1.svg",
  ];
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: VR Company | AR Company | Car 360 view</title>
        <meta
          name="description"
          content="We're a VR, AR company that creates visual 3D, product configurators, and augmented reality for car 360 view, ecommerce, and sales"
        />
        <meta name="keywords" content="VR company, AR Company, Car 360 view" />

        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../videos/thumbnail/3d_ar.jpg" />
      </Helmet>
      <div className="container-fluid home">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="section-1">
          <CommonSection
            title="Beyond Digital"
            sub="Our AI driven platform is your one stop solution to create immersive, interactive, AR, VR visual catalog in minutes"
            buttonText="REQUEST A DEMO"
            isHomepage={true}
          >
            <VideoPlayer
              videoUrl="../../videos/ar-vr-company.mp4"
              videoThumbnail="../../images/thumbnail/index.webp"
            />
          </CommonSection>
        </div>
        <div className="spacer-sm"></div>
        <ClientLogoBanner logoList={logos} />
        <div className="spacer"></div>
        <FeatureExplorer />
        <div className="spacer"></div>
        <StatColumn
          title="Why transform your 2d gallery to 3d catalog?"
          sub=""
          items={[
            {
              stat: "40%",
              info: "40% increase in sales",
            },
            {
              stat: "200%",
              info: "Upto 200% increase in engagement",
            },
            {
              stat: "20%",
              info: "Upto 20% less return",
            },
          ]}
          noFlex
        />
        <div className="spacer"></div>
        {/* <CaseStudy /> */}
        <div className="spacer"></div>
        <div className="section-5">
          <div>
            <h1 className="h1 text-dark text-center mb-4">
              Domains we’ve worked with
            </h1>
            <DomainsCarousel />
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <Testimonials />
        <div className="spacer"></div>
        <AccordionSection
          title="Advantages of HelloAR Platform"
          items={[
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "Scale at speed",
              sub: "Create or convert 2D images into 3D product catalogs at bulk with our AI driven software.",
            },
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "Pricing",
              sub: "Transparent and customizable pricing. We have solutions for both enterprise and SMBs sales and marketing teams.",
            },
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "Easy to use no code platform",
              sub: "Our platform is extremely simple to use. You can get started almost immediately.",
            },
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "Works on every device",
              sub: "HelloAR platform works on every device and on every platform.",
            },
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "24/7 Support",
              sub: "We are with you at every step and help you get comfortable with the platform whenever you need it.",
            },
            {
              image: "./images/ar-vr-companies.png",
              imageWebp: "./images/ar-vr-companies.webp",
              title: "Get started in minutes",
              sub: "You can integrate our software in a few simple steps. You can either install code, or use an API, or opt for a SDK integration.",
            },
          ]}
          mainIndex={3}
        />
        <div className="spacer"></div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light px-3">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        {/* <div className="spacer"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </div>
    </Layout>
  );
};

export default Home;
