import { Link } from "gatsby";
import React from "react";
import "./StatColumn.scss";
import { Container, Row, Col } from "react-bootstrap";

interface StatInfo {
  stat: string;
  info: string;
}

interface IProps {
  title: string;
  sub?: string;
  items?: StatInfo[];
  noFlex?: boolean;
}

const StatColumn: React.FC<IProps> = ({ title, sub, items, noFlex }) => {
  return (
    <div className="statInfoContainer">
      <Container>
        <h1 className="h1 text-center font-weight-bold">{title}</h1>
        {sub && sub.length > 0 && <p className="text-center">{sub}</p>}
        <div className="spacer-sm"></div>
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              xs={12}
              md={3}
              key={index}
              className="item d-flex flex-column align-items-stretch"
            >
              <h1
                className={`text-center ${
                  items.length == 4 ? (noFlex ? "" : "flex1") : ""
                }`}
              >
                {item.stat}
              </h1>
              <p
                className={`${
                  items.length == 4 ? "w-100" : " mx-auto"
                } text-center`}
              >
                {item.info}
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default StatColumn;
